import TranscriptHandler from './transcript_handler';

class AudioRecordingService {
  constructor(csrfToken, chatService, audioPlayerService, recordBtn, micIcon, pauseBtn) {
    this.chatService = chatService;
    this.audioPlayerService = audioPlayerService;
    this.chatSubmitBtn = chatService.chatSubmitBtn;
    this.chatInput = chatService.chatInput;
    this.addChatLoader = chatService.addChatLoader;
    this.removeChatLoader = chatService.removeChatLoader;
    this.csrfToken = csrfToken;
    this.language = chatService.language;
    this.languageCode = chatService.languageCode;
    this.dialectCode = chatService.dialectCode;

    this.recordBtn = recordBtn;
    this.micIcon = micIcon;
    this.pauseBtn = pauseBtn;

    this.micAccessGranted = false;
    this.vadSetup = false;
    this.mediaRecorder;
    this.audioChunks = [];
    this.isRecording = this.chatService.isRecording;
    this.timer;
    this.myvad;
    this.microphone = null;

    this.model = this.determineModel(chatService.chatOptions.transcriptionMode);

    this.useDeepgram = chatService.chatOptions.transcriptionMode === 'v2';
    this.transcriptHandler = new TranscriptHandler(this.chatInput);
    this.pauseBtnClicked = false;
    this.recordBtnClicked = false;
    this.reconnectionAttempts = 0;
    this.maxReconnectionAttempts = 5;
    this.reconnectionDelay = 2000; // 2 seconds
    this.isReconnecting = false;
    this.isIntentionalDisconnect = false;
    this.keepAlive = null;
  }

  determineModel(transcriptionMode) {
    switch(transcriptionMode) {
      case 'v1':
        return 'whisper-1';
      case 'v2':
        return 'deepgram';
      case 'v3':
        return 'whisper-turbo';
      case 'v4':
        return 'gemini';
      case 'v5':
        return 'gemini-pro';
      default:
        return 'whisper-1';
    }
  }

  async getMicrophone() {
    if (this.microphone !== null) {
      return;
    }

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput');

      if (audioDevices.length > 0) {
        this.microphone = audioDevices[0].deviceId;

        if (this.chatService.chatOptions.selectedMicrophone !== this.microphone) {
          this.chatService.chatOptions.selectedMicrophone = this.microphone;
        }
      } else {
        console.error('No audio input devices found');
        this.microphone = null;
      }
    } catch (error) {
      console.error('Error getting microphone:', error);
      this.microphone = null;
    }
  }

  async updateMicrophone(deviceId) {
    try {
      this.microphone = deviceId;
      this.vadSetup = false;
      const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: this.microphone } })
      await this.setupAudio(stream);
      await this.setupVAD(stream);
    } catch (error) {
      console.error('Error updating microphone:', error);
    }
  }

  async checkMicAccess() {
    try {
      await this.getMicrophone();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: this.microphone } })
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.stream = stream;

      if (stream.getAudioTracks()[0].muted) {
        this.micAccessGranted = false;

        this.openModal(true);
        this.disableRecordBtn();
        return false;
      } else {
        await this.setupAudio(stream);
        await this.setupRecordBtn();
        return true;
      }

    } catch (error) {
      console.log("Microphone access denied:", error);
      this.micAccessGranted = false;

      if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        console.log('User denied microphone access', error);
      } else {
        console.log('Error accessing microphone:', error);
      }

      this.openModal();

      return false;
    }
  }

  // Preserves the audio output device when the user grants mic access
  async setupAudio(stream) {
    try {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const destination = audioContext.createMediaStreamDestination();

      const audioElement = new Audio();
      audioElement.srcObject = destination.stream;

      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput');
      const audioInputDevices = devices.filter(device => device.kind === 'audioinput');

      if (typeof audioElement.setSinkId === 'function') {
        if (audioOutputDevices.length > 0) {
          await audioElement.setSinkId(audioOutputDevices[0].deviceId);
        } else if (audioInputDevices.length > 0) {
          await audioElement.setSinkId(audioInputDevices[0].deviceId);
        } else {
          console.log("Error setting up audio - no devices found");
        }

        audioElement.play();
      }
    } catch (error) {
      console.error("Error setting up audio:", error);
    }
  }

  buildModal(muted = false) {
    const modalHTML = `
      <div class="modal fade" id="micErrorModal" tabindex="-1" role="dialog" aria-labelledby="chatOptionsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="chatOptionsModalLabel">Langua is blocked from using your microphone.</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              ${muted ? '<p>Your microphone is currently muted. Please unmute your microphone to enable recording. <a href="https://support.languatalk.com/article/148-im-experiencing-issues-with-the-ai-conversation-feature-communicate-what-should-i-do#3-I-cannot-record-what-I-say-IrzA3" target="_blank">Please see here</a> for instructions.</p>' :
                '<p> To enable microphone access, you may need to unblock Langua at the browser level. <a href="https://support.languatalk.com/article/148-im-experiencing-issues-with-the-ai-conversation-feature-communicate-what-should-i-do#3-I-cannot-record-what-I-say-IrzA3" target="_blank">Please see here</a> for instructions.</p>'
              }
              <p>
                Once you've done this, you'll be able to enable the mic via the 'Enable microphone access' link below.
              </p>
            </div>
          </div>
        </div>
      </div>
    `;

    // Append the modal HTML to the body
    document.body.insertAdjacentHTML('beforeend', modalHTML);
  }

  showModal() {
    $('#micErrorModal').modal('show');
  }

  destroyModal() {
    $('#micErrorModal').modal('dispose');
  }

  openModal(muted = false) {
    // Check if the modal already exists
    if (document.getElementById('micErrorModal')) {
      this.destroyModal();
    }

    this.buildModal(muted);
    this.showModal();
  }
  
  async setupRecordBtn() {
    const that = this;
    that.micAccessGranted = true;

    if (that.useDeepgram) {
      await that.setupDeepgram();
      that.setupBasicRecorder(that.stream);
    } else {
      if (!that.vadSetup) {
        await that.setupVAD(that.stream);
        that.enableRecordBtn();
      }
    }

    // setup event listener for record button
    that.recordBtn.addEventListener('click', that.recordBtnClick.bind(that));

    that.pauseBtn.addEventListener('click', that.pauseBtnClick.bind(that));

    // Listen to the playended custom event
    document.addEventListener('chat:audioended', () => {
      if (that.chatService.chatOptions.autoRecord && that.micAccessGranted) {
        that.startRecording();
      }
    });

    document.addEventListener('chat:audiostarted', () => {
      if (that.isRecording) {
        that.stopRecording();
      }
    });

    document.addEventListener('chat:pronounciationstarted', () => {
      if (that.isRecording) {
        that.stopRecording();
      }
    });
  }

  pauseBtnClick(event) {
    event.preventDefault();

    this.pauseBtnClicked = true;

    this.toggleRecording();

    if (!this.isRecording && this.chatInput.value.length > 0) {
      this.chatSubmitBtn.disabled = false;
      this.chatSubmitBtn.classList.remove('d-none');
    }
  }

  recordBtnClick(event) {
    event.preventDefault();

    if (this.isRecording) {
      this.recordBtnClicked = true;
    }

    this.toggleRecording();

    if (this.useDeepgram && !this.isRecording && this.chatInput.value.length > 0) {
      this.chatSubmitBtn.disabled = false;
      this.chatSubmitBtn.classList.remove('d-none');
      this.chatSubmitBtn.click();
    }
  }

  toggleRecording() {
    const that = this;
    if (that.isRecording) {
      that.stopRecording();
    } else {
      if (that.audioPlayerService.soundPlaying && that.audioPlayerService.player.playing()) {
        that.audioPlayerService.togglePlayer();
      }
      that.startRecording();
    }
  }

  showConnectingMessage() {
    if (!document.querySelector('.js-deepgram-connecting')) {
      this.recordBtn.insertAdjacentHTML('beforebegin', '<span class="js-deepgram-connecting" style="padding: 0.5rem 1rem; font-size: 1rem;">Connecting...</span>');
    }
  }

  hideConnectingMessage() {
    const connectingMessage = document.querySelector('.js-deepgram-connecting');
    if (connectingMessage) {
      connectingMessage.remove();
    }
  }

  showEnableMicAccessLink() {
    const that = this;
    // check if the enable mic access link already exists
    if (!document.querySelector('.js-enable-mic-access')) {
      that.recordBtn.insertAdjacentHTML('afterend', '<a href="#" class="js-enable-mic-access btn btn-link">Enable microphone access</a>');
      document.querySelector('.js-enable-mic-access').addEventListener('click', async function (e) {
        e.preventDefault();
        const hasMicAccess = await that.checkMicAccess();
        if (hasMicAccess) {
          this.remove();
          that.recordBtn.classList.remove('d-none');
          that.recordBtn.disabled = false;
        }
      });
    }
  }

  disableRecordBtn() {
    const that = this;
    that.recordBtn.disabled = true;
    that.recordBtn.classList.add('d-none');
    that.pauseBtn.disabled = true;
    that.pauseBtn.classList.add('d-none');

    if (that.isReconnecting) {
      that.showConnectingMessage();
    } else {
      that.showEnableMicAccessLink();
    }
  }

  enableRecordBtn() {
    this.recordBtn.disabled = false;
    this.recordBtn.classList.remove('d-none');
    if (this.useDeepgram) {
      this.hideConnectingMessage();
    }
  }

  trackMicrophonePermission() {
    const that = this;
    navigator.permissions.query({ name: 'microphone' })
      .then(function(permissionStatus) {
        console.log('Microphone permission state:', permissionStatus.state);

        // Listen for changes in the permission state
        permissionStatus.onchange = function() {
          console.log('Microphone permission state changed to:', this.state);
          // Handle the change in permission state
          if (this.state === 'granted') {
            console.log('Microphone access is now allowed');
          } else if (this.state === 'denied') {
            console.log('Microphone access is now denied');
            that.disableRecordBtn();
          } else if (this.state === 'prompt') {
            console.log('Microphone access is now prompt');
            that.disableRecordBtn();
          }
        };
      })
      .catch(function(error) {
        console.log('Error querying microphone permission:', error);
      });
  }

  async setup() {
    if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.log('Missing support for navigator.mediaDevices.getUserMedia');
      return;
    }

    const that = this;

    that.trackMicrophonePermission();

    if (that.stream) {
      if (that.stream.getAudioTracks()[0].muted) {
        that.micAccessGranted = false;
        that.disableRecordBtn();

        that.openModal(true);
      } else {
        await that.setupAudio(that.stream);
        await that.setupRecordBtn();
      }
    } else {
      // Check for getUserMedia support
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

        await that.getMicrophone();

        navigator.mediaDevices.getUserMedia({ audio: { deviceId: that.microphone } })
          .then(async stream => {
            that.stream = stream;

            if (stream.getAudioTracks()[0].muted) {
              that.micAccessGranted = false;
              that.disableRecordBtn();

              that.openModal(true);
            } else {
              await that.setupAudio(stream);
              await that.setupRecordBtn();
            }

            this.chatService.chatOptions.audioRecordingService = that;
          })
          .catch(error => {
            console.log("Error accessing the microphone:", error);
            that.disableRecordBtn();
          });
      } else {
        console.log("Your browser doesn't support the MediaDevices API");
      }
    }
  }

  async setupVAD(stream) {
    const that = this;

    try {
      if (typeof vad !== 'undefined' && vad.MicVAD) {
        that.myvad = await vad.MicVAD.new({
          redemptionFrames: that.chatService.chatOptions.autoSend ? 30 : 80,
          submitUserSpeechOnPause: true,
          onSpeechStart: () => {
            that.recordBtn.innerHTML = '<i class="fa fa-paper-plane"></i> Send';
            that.recordBtn.classList.add('btn-chat-send');
            that.recordBtn.classList.remove('btn-chat-record');
          },
          onSpeechEnd: async (audio) => {
            if (that.isRecording) {
              that.stopRecording();
            }

            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const audioBuffer = audioContext.createBuffer(1, audio.length, 16000);
            const channelData = audioBuffer.getChannelData(0);
            channelData.set(audio);

            const audioBlob = await new Promise((resolve) => {
              that.audioBufferToWavBlob(audioBuffer, (blob) => {
                resolve(blob);
              });
            });

            that.sendDataToRailsBackend(audioBlob);

            that.audioChunks = [];
          }
        });

        that.vadSetup = true;
      } else {
        console.error('Error initializing VAD');
        that.vadSetup = false;
        that.setupBasicRecorder(stream);
      }
    } catch (error) {
      console.error('Error initializing VAD:', error);
      that.vadSetup = false;
      // Fallback to basic recorder without VAD
      that.setupBasicRecorder(stream);
    }
  }

  audioBufferToWavBlob(audioBuffer, callback) {
    const that = this;
    const audioData = audioBuffer.getChannelData(0);
    const buffer = new ArrayBuffer(44 + audioData.length * 2);
    const view = new DataView(buffer);
    const sampleRate = audioBuffer.sampleRate;
    const numChannels = audioBuffer.numberOfChannels;

    that.writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + audioData.length * 2, true);
    that.writeString(view, 8, 'WAVE');
    that.writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 4, true);
    view.setUint16(32, numChannels * 2, true);
    view.setUint16(34, 16, true);
    that.writeString(view, 36, 'data');
    view.setUint32(40, audioData.length * 2, true);

    that.floatTo16BitPCM(view, 44, audioData);

    const blob = new Blob([view], { type: 'audio/wav' });
    callback(blob);
  }

  writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  floatTo16BitPCM(output, offset, input) {
    for (let i = 0; i < input.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
  }

  async setupBasicRecorder(stream) {
    const that = this;

    // use AudioRecorder polyfill if MediaRecorder is not supported
    if (window.MediaRecorder === undefined) {
      import(/* webpackChunkName: "AudioRecorder" */ 'audio-recorder-polyfill').then((AudioRecorderModule) => {
        import(/* webpackChunkName: "mpegEncoder" */ 'audio-recorder-polyfill/mpeg-encoder').then((mpegEncoderModule) => {
          const AudioRecorder = AudioRecorderModule.default;
          AudioRecorder.encoder = mpegEncoderModule.default;
          AudioRecorder.prototype.mimeType = 'audio/mpeg';
          that.mediaRecorder = new AudioRecorder(stream);

          that.setupMediaRecorder()
        });
      });
    } else {
      that.mediaRecorder = new MediaRecorder(stream);
      that.setupMediaRecorder()
    }
  }

  setupMediaRecorder() {
    const that = this;

    that.dataAvailableHandler = e => {
      if (that.useDeepgram && e.data.size > 0 && that.isRecording) {
        this.socket.send(e.data);
      }

      that.audioChunks.push(e.data);
    };

    that.stopRecordingHandler = e => {
      if (!that.useDeepgram) {
        const audioBlob = new Blob(that.audioChunks, { type: "audio/mpeg" });

        that.sendDataToRailsBackend(audioBlob);
      }

      that.audioChunks = []; // Reset audio chunks
    }

    that.mediaRecorder.addEventListener('dataavailable', that.dataAvailableHandler);

    that.mediaRecorder.addEventListener('stop', that.stopRecordingHandler);
  }

  resetAudioChunks() {
    this.audioChunks = [];
    if (this.mediaRecorder) {
      this.mediaRecorder.removeEventListener('dataavailable', this.dataAvailableHandler);
      this.mediaRecorder.removeEventListener('stop', this.stopRecordingHandler);
      this.setupMediaRecorder();
    }
  }

  // setup 60 second timer that gets cancelled if user stops recording before 60 seconds
  setupTimer() {
    const that = this;

    that.timer = setTimeout(function () {
      that.stopRecording();
    }, 60000);
  }

  async startRecording() {
    const that = this;

    that.isRecording = true;
    that.chatService.isRecording = true;
    that.chatSubmitBtn.classList.add('d-none');

    if (that.useDeepgram) {
      if (!this.deepgramConnected()) {
        await that.setupDeepgram();
        that.setupBasicRecorder(that.stream);
      }

      if (this.chatInput.value.length === 0) {
        this.transcriptHandler.clearTranscript();
      }

      if (that.mediaRecorder.state === "paused") {
        that.mediaRecorder.resume();
      } else {
        that.mediaRecorder.start(100);
      }
      that.setupTimer();
    } else {

      if (this.vadSetup) {
        this.myvad.start();
      } else {
        await this.mediaRecorder.start();
        that.setupTimer();
      }
    }

    that.pauseBtn.disabled = false;
    that.pauseBtn.classList.remove('d-none');
    that.recordBtn.innerHTML = '<i class="fa fa-paper-plane"></i> Send';
    that.recordBtn.classList.remove('btn-chat-record');
    that.recordBtn.classList.add('pulse');
    that.recordBtn.classList.add('btn-chat-send');
  }
  
  async updateTranscriptionMode(transcriptionMode) {

    this.recordBtn.replaceWith(this.recordBtn.cloneNode(true));
    this.recordBtn = document.getElementById("js-record-query-btn");

    this.pauseBtn.replaceWith(this.pauseBtn.cloneNode(true));
    this.pauseBtn = document.getElementById("js-pause-recording-btn");

    if (transcriptionMode === 'v2') {
      this.useDeepgram = true;
      this.model = this.determineModel(transcriptionMode)

      if (!this.deepgramConnected()) {
        this.showConnectingMessage();
      }

      this.setupBasicRecorder(this.stream);
    } else {
      this.useDeepgram = false;
      this.tearDownDeepgram();
      this.model = this.determineModel(transcriptionMode);
    }

    this.setup();
  }

  async getDeepgramApiKey() {
    // Fetch API key
    const response = await fetch('/langua/deepgram_keys', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({ chat_id: this.chatService.chatId })
    })
    const data = await response.json();
    const apiKey = data.api_key;

    return apiKey;
  }

  deepgramConnected() {
    return this.deepgram && this.socket && this.socket.getReadyState() === 1;
  }

  async setupDeepgram() {

    const that = this;

    if (that.deepgramConnected()) {
      return;
    }

    const { createClient } = deepgram;
    const apiKey = await that.getDeepgramApiKey();

    that.keepAlive = null;
    that.deepgram = createClient(apiKey);
    that.isIntentionalDisconnect = false;

    let language_or_dialect = (that.dialectCode && that.dialectCode.includes('en')) ? that.dialectCode : that.languageCode;

    that.socket = that.deepgram.listen.live({ 
      model: "nova-2",
      language: language_or_dialect,
      interim_results: true,
      smart_format: true,
      endpointing: 550,
      utterance_end_ms: 1500,
      filler_words: true,
      });

    that.socket.on("open", async () => {
      that.isReconnecting = false;
      that.enableRecordBtn();

      if (that.keepAlive !== null) clearInterval(that.keepAlive);
      that.keepAlive = setInterval(() => {
        that.socket.keepAlive();
      }, 3000);

      that.socket.on("Results", (data) => {
        if (that.isRecording) {
          that.transcriptHandler.processTranscript(data);
        }
      });

      that.socket.on("error", (e) => {
        console.error("Deepgram error:", e);
        that.handleDeepgramError(e);
      });

      that.socket.on("warning", (e) => console.warn(e));

      that.socket.on("Metadata", (e) => console.log(e));

      that.socket.on("close", (e) => {
        console.log("client: disconnected from websocket", e);
        clearInterval(that.keepAlive);
        that.handleDeepgramDisconnection();
      });
    });
  }

  handleDeepgramError(error) {
    console.error("Deepgram error:", error);
    this.handleDeepgramDisconnection();
  }

  handleDeepgramDisconnection() {
    if (this.isRecording) {
      this.stopRecording();
    }

    if (this.isIntentionalDisconnect) {
      return;
    }

    this.isReconnecting = true;
    this.disableRecordBtn();
    this.reconnectToDeepgram();
  }

  async reconnectToDeepgram() {
    if (this.isIntentionalDisconnect) {
      console.log("Intentional disconnect detected, cancelling reconnection.");
      this.isReconnecting = false;
      return;
    }

    if (this.reconnectionAttempts < this.maxReconnectionAttempts) {
      this.reconnectionAttempts++;
      console.log(`Attempting to reconnect to Deepgram (Attempt ${this.reconnectionAttempts})`);

      this.showConnectingMessage();

      setTimeout(async () => {
        try {
          await this.setupDeepgram();
        } catch (error) {
          console.error("Failed to reconnect to Deepgram:", error);
          this.reconnectToDeepgram(); // Try again
        }
      }, this.reconnectionDelay);
    } else {
      console.error("Max reconnection attempts reached. Please try again later.");
      this.isReconnecting = false;
      this.hideConnectingMessage();
      this.showEnableMicAccessLink();
    }
  }

  tearDownDeepgram() {
    this.isIntentionalDisconnect = true;
    if (this.deepgram && this.socket) {
      this.socket.finish();
      this.socket = null;
      this.deepgram = null;
    }
    if (this.keepAlive) {
      clearInterval(this.keepAlive);
      this.keepAlive = null;
    }
    this.isReconnecting = false;
    this.hideConnectingMessage();
  }

  sendDataToDeepgram(audioBlob) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      this.socket.send(arrayBuffer);
    };
    reader.readAsArrayBuffer(audioBlob);
  }

  stopRecording() {
    this.isRecording = false;
    this.chatService.isRecording = false;
    this.recordBtn.classList.remove('pulse');
    this.recordBtn.innerHTML = '<i class="fa fa-microphone"></i> Record';
    this.recordBtn.classList.add('btn-chat-record');
    this.recordBtn.classList.remove('btn-chat-send');
    this.pauseBtn.classList.add('d-none');
    this.pauseBtn.disabled = true;
    clearTimeout(this.timer);

    if (this.useDeepgram) {
      if (this.mediaRecorder.state === "recording") {
        this.mediaRecorder.pause();
        this.resetAudioChunks();
        this.socket.finalize();
      }
    } else if (this.vadSetup) {
      this.myvad.pause();
    }
  }

  playRecording(audioBlob) {
    const audioUrl = URL.createObjectURL(audioBlob);
    this.chatService.audioPlayerService.playSound(audioUrl, this.micIcon);
  }

  shouldBeSubmitted() {
    return this.recordBtnClicked || (this.chatService.chatOptions.autoSend && !this.pauseBtnClicked);
  }

  // Function to send audio data to Rails backend
  sendDataToRailsBackend(audioBlob) {
    // show loader inside chat input
    this.addChatLoader(this.chatService.chatElement);
    // disable mic button
    this.recordBtn.disabled = true;
    this.pauseBtn.disabled = true;
    this.chatSubmitBtn.disabled = true;

    const formData = new FormData();
    formData.append("audio", audioBlob, "recorded_audio.wav");
    formData.append("language", this.language);
    formData.append("model", this.model);
    formData.append("dialect", this.dialectCode);

    fetch('/langua/speech_to_text', {
      method: "POST",
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      credentials: 'same-origin',
      body: formData
    }).then(response => {
      return response.json();
    }).then(data => {
      // add the value the chat input to the response
      if (data.text !== undefined) {
        if (this.chatInput.value.length > 0) {
          this.chatInput.value = `${this.chatInput.value} ${data.text}`;
        } else {
          this.chatInput.value = data.text;
        }
        this.recordBtn.disabled = false;
        this.pauseBtn.disabled = false;
        this.chatSubmitBtn.disabled = false;
        this.removeChatLoader(this.chatService.chatElement);
        if (this.shouldBeSubmitted()) {
          this.chatSubmitBtn.click();
        } else if (this.pauseBtnClicked) {
          this.chatSubmitBtn.classList.remove('d-none');
        }
        this.pauseBtnClicked = false;
        this.recordBtnClicked = false;
      } else {
        this.pauseBtnClicked = false;
        this.recordBtnClicked = false;
        this.recordBtn.disabled = false;
        this.pauseBtn.disabled = false;
        this.chatSubmitBtn.disabled = false;
        this.removeChatLoader(this.chatService.chatElement);
      }

    }).catch(error => {
      console.log("Error in sending audio: ", error);
      this.chatSubmitBtn.disabled = false;
      this.removeChatLoader(this.chatService.chatElement);

    }).catch(error => {
      console.log("Error in sending audio: ", error);
      this.chatSubmitBtn.disabled = false;
      this.removeChatLoader(this.chatService.chatElement);
    });
  }
}

export default AudioRecordingService;
