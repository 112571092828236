import ChatTopicService from './chat/chat_topic_service';

document.addEventListener('turbolinks:load', function() {
  const chatForm = document.getElementById('new-chat-form');

  if (chatForm) {
    const chatTopic = document.getElementById('js-chat-topic');
    const chatTopicCategory = document.getElementById('js-chat-topic-category');
    const chatSubtopicCategory = document.getElementById('js-chat-subtopic-category');
    const continueConversationBtn = document.querySelector('.js-continue-conversation-btn');

    const chatModeButtons = document.querySelectorAll('.js-chat-mode-radio-label');
    const chatVoiceOptionsEl = document.querySelectorAll('.chat-voice-options-container');
    const chatVoiceSelect = document.getElementById('chat_form_voice');
    let chatMode = document.querySelector('.js-chat-mode-radio-label.active').innerText;
    let chatVoice = chatVoiceSelect.options[chatVoiceSelect.selectedIndex].label;
    
    const chatElementContainer = document.querySelector('.chat-content-w');
    const chatElement = document.querySelector('.chat-content');

    const chatTopicService = new ChatTopicService(chatElementContainer, chatElement);

    $('body').on('click', '.js-chat-options-btn', function (e) {
      $('#chatOptionsModal').modal('show');
    });

    // Event listener for main category buttons (e.g., 'Roleplay')
    $(document).on('click', '.js-main-chat-option-btn', function(event) {
      event.preventDefault();

      if (continueConversationBtn) {
        continueConversationBtn.classList.add('d-none');
      }

      const topicCategory = $(this).data('topic-category');
      const subOptionsContainer = $('.js-sub-options-container');
      const categories = $(this).data('topics');

      chatTopicService.showSubCategories(topicCategory, categories, subOptionsContainer);
    });

    // Event listener for subcategory buttons within a main category
    $(document).on('click', '.js-sub-category-btn', function(event) {
      event.preventDefault();

      const topicCategory = $(this).data('topic-category');
      const topicsData = $(this).data('topics');
      const subtopicCategory = $(this).data('subtopic-category');
      const vocabSubtopic = (topicCategory === 'vocab' && subtopicCategory === 'practice_my_vocab_blend_into_chat') ||
          (topicCategory === 'vocab' && subtopicCategory === 'practice_my_vocab_word_by_word');

      if (topicCategory === 'roleplay' || vocabSubtopic) {
        chatTopicService.showSubOptions(topicCategory, subtopicCategory, topicsData);
      } else {
        chatTopicCategory.value = topicCategory;
        chatTopic.value = subtopicCategory;
        chatForm.submit();
      }
    });

    // Event listener for final topic selection
    $(document).on('click', '.js-sub-chat-option-btn', function(event) {
      event.preventDefault();

      const topic = $(this).data('topic');
      const topicCategory = $(this).data('topic-category');
      const subtopicCategory = $(this).data('subtopic-category');

      chatTopicCategory.value = topicCategory;
      chatSubtopicCategory.value = subtopicCategory;
      chatTopic.value = topic;
      chatForm.submit();
    });

    chatModeButtons.forEach(function(button) {
      button.addEventListener('click', function(e) {

        // Remove active class from all buttons
        chatModeButtons.forEach(function(btn) { btn.classList.remove('active'); });

        // Add active class to the clicked button
        this.classList.add('active');

        // Ensure the associated radio button is checked
        var inputElement = this.previousElementSibling;
        inputElement.checked = true;

        // Get input value from input above the label
        var modeValue = inputElement.value;

        switch(modeValue) {
          case 'text_only':
            chatVoiceOptionsEl.forEach(el => el.classList.add('d-none'));
            break;
          case 'audio_only':
            chatVoiceOptionsEl.forEach(el => el.classList.remove('d-none'));
            break;
          case 'text_audio':
            chatVoiceOptionsEl.forEach(el => el.classList.remove('d-none'));
            break;
        }

        // Set chat mode to be button text
        chatMode = e.target.innerText;
        updateChatOptionsDisplay();
      });
    });

    chatVoiceSelect.addEventListener('change', function(e) {
      // set chat voice to be the label of the selected option
      chatVoice = e.target.options[e.target.selectedIndex].label;
      updateChatOptionsDisplay();
    });

    function updateChatOptionsDisplay() {
      if (chatMode === 'Text only') {
        $('.js-display-chat-options').text(`You will be chatting in ${chatMode} mode`);
      } else if (chatVoice) {
        $('.js-display-chat-options').text(`You will be chatting with ${chatVoice} in ${chatMode} mode`);
      }
    }
  }
});
